import * as React from "react"
import DefaultLayout from "../templates/DefaultLayout"
import Seo from "../components/seo"
import Button from "../components/Button/Button"
import EbookImage from "../image/lawlite-content/ebook.jpg"
// import { CheckIcon } from "@heroicons/react/solid"
import HubspotForm from 'react-hubspot-form'
import CheckIcon from '../assets/check-mark.svg';
const points = [
  `Proven solutions to remove the barriers to growth`,
  `Hard earned lessons to take your practice to the next level`,
  `Leverage yourself and make your practice work for you`,
  `Practical techniques that actually work`,
]
const IndexPage = () => (
  <DefaultLayout>
    <Seo
      title="LawLite E-book"
      description={
        "The legal practice playbook from LawLite. 10 Keys to Systematically Grow your Legal Practice"
      }
    />
    <div className="min-h-[80vh] flex flex-col py-10 items-center justify-center text-br-primary-blue text-center px-4 ">
      <h2 className="text-md md:text-xl font-bold mb-2">
        {" "}
        The legal practice playbook from LawLite
      </h2>
      <h3 className="text-xl md:text-2xl font-bold mb-2">
        {" "}
        "10 Keys to Systematically Grow your Legal Practice"
      </h3>
      <div className="h-[400px] max-h-[40vh]">
        <img
          src={EbookImage}
          alt={"Lawlite E book"}
          className="h-full object-contain"
        />
      </div>
      <HubspotForm
        portalId="19910559"
        formId="ae811301-0e85-4303-bc69-37ac172ced5a"
        onSubmit={() => console.log("Submit!")}
        loading={<div>Loading...</div>}
      />

      <ul className="text-left text-base md:text-lg font-bold">
        {points.map(x => {
          return (
            <li className="ml-0 flex md:items-center" key={"x"}>
              <CheckIcon className="h-6 md:h-8 flex-shrink-0 mr-2 fill-current" />
              {x}
            </li>
          )
        })}
      </ul>
    </div>
  </DefaultLayout>
)

export default IndexPage
